<template>
  <div class="leap-year-visualization">
    <!-- 标题与统计 -->
    <div class="stats-panel">
      <h2>1900 - 2025 年闰年时空图鉴</h2>
      <div class="meta-info">
        <div class="stat-item">
          <div class="stat-value">{{ totalYears }}</div>
          <div class="stat-label">总年数</div>
        </div>
        <div class="stat-item">
          <div class="stat-value">{{ leapYears.length }}</div>
          <div class="stat-label">闰年数</div>
        </div>
        <div class="stat-item">
          <div class="stat-value">{{ leapPercent }}%</div>
          <div class="stat-label">占比</div>
        </div>
      </div>
    </div>

    <!-- 3D时间轴 -->
    <div class="timeline-container">
      <div 
        v-for="(year, index) in groupedYears"
        :key="year.century"
        class="century-block"
        :style="{ transform: `rotateX(${index * 15}deg)` }"
      >
        <div class="century-header">{{ year.century }}世纪</div>
        <div class="year-grid">
          <div 
            v-for="y in year.years"
            :key="y"
            class="year-card"
            :class="{ leap: isLeapYear(y) }"
            @mouseenter="showTooltip(y)"
          >
            {{ y }}
            <div class="year-dot"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 浮动提示 -->
    <div v-if="activeYear" class="floating-tooltip">
      <div class="tooltip-content">
        <h3>{{ activeYear }}年</h3>
        <p>历史同期事件：{{ historicalEvents[activeYear % 10] }}</p>
        <div class="moon-phase"></div>
      </div>
    </div>

    <!-- 统计图表 -->
    <div class="chart-container">
      <div 
        v-for="(item, index) in centuryData"
        :key="index"
        class="chart-bar"
        :style="{ height: item.height + 'px' }"
      >
        <div class="bar-label">{{ item.century }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeYear: null,
      historicalEvents: [
        '第一次世界大战开始',
        '阿波罗登月计划',
        '互联网诞生',
        '柏林墙倒塌',
        '北京奥运会',
        'COVID-19大流行',
        '人工智能突破',
        '量子计算机诞生',
        '火星殖民开始',
        '气候协定签署'
      ]
    }
  },
  computed: {
    leapYears() {
      const years = [];
      for (let y = 1900; y <= 2025; y++) {
        if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) years.push(y);
      }
      return years;
    },
    groupedYears() {
      return Array.from({ length: 13 }, (_, i) => ({
        century: 19 + Math.floor(i/10),
        years: Array.from({ length: 10 }, (_, j) => 1900 + i*10 + j)
      }));
    },
    totalYears() { return 2025 - 1900 + 1 },
    leapPercent() { return ((this.leapYears.length / this.totalYears * 100)).toFixed(1) },
    centuryData() {
      return this.groupedYears.map(g => ({
        century: g.century + '世纪',
        height: g.years.filter(y => this.leapYears.includes(y)).length * 8
      }))
    }
  },
  methods: {
    isLeapYear(y) { return this.leapYears.includes(y) },
    showTooltip(y) { this.activeYear = y }
  }
}
</script>

<style scoped>
.leap-year-visualization {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  color: #333;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.stats-panel {
  text-align: center;
  margin-bottom: 3rem;
}

.meta-info {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 1.5rem;
  border-bottom: 2px solid #2ecc71;
  padding-bottom: 2rem;
}

.stat-item {
  background: rgba(46, 204, 113, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
  min-width: 120px;
  transition: transform 0.3s;
  border: 1px solid rgba(46, 204, 113, 0.2);
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-value {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, #2ecc71, #27ae60);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.timeline-container {
  perspective: 1000px;
  height: 600px;
  overflow-y: auto;
  padding: 2rem;
  background: linear-gradient(to bottom, 
    rgba(46, 204, 113, 0.05) 0%,
    rgba(46, 204, 113, 0.02) 100%
  );
}

.century-block {
  background: rgba(46, 204, 113, 0.05);
  padding: 1.5rem;
  margin: 2rem 0;
  border-radius: 15px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  border: 1px solid rgba(46, 204, 113, 0.1);
}

.century-header {
  font-size: 1.5rem;
  color: #27ae60;
  margin-bottom: 1rem;
}

.year-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 1rem;
}

.year-card {
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(46, 204, 113, 0.08);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid rgba(46, 204, 113, 0.15);
}

.year-card:hover {
  background: rgba(46, 204, 113, 0.15);
  transform: translateY(-3px) scale(1.05);
}

.year-card.leap {
  background: linear-gradient(45deg, #2ecc71, #27ae60);
  box-shadow: 0 4px 15px rgba(46, 204, 113, 0.3);
}

.year-dot {
  position: absolute;
  bottom: 8px;
  width: 6px;
  height: 6px;
  background: #2ecc71;
  border-radius: 50%;
  opacity: 0.8;
}

.floating-tooltip {
  position: fixed;
  background: #fff;
  padding: 1.5rem;
  border-radius: 15px;
  border: 1px solid #2ecc71;
  box-shadow: 0 10px 30px rgba(46, 204, 113, 0.2);
  color: #333;
}

.chart-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.chart-bar {
  width: 60px;
  background: linear-gradient(to top, #2ecc71, #27ae60);
  border-radius: 8px 8px 0 0;
  position: relative;
  transition: height 0.5s;
}

.bar-label {
  position: absolute;
  bottom: -30px;
  transform: rotate(-45deg);
  white-space: nowrap;
}

@keyframes timeline-entry {
  from { 
    transform: rotateX(90deg); 
    opacity: 0;
    background: rgba(46, 204, 113, 0.3);
  }
  to { 
    transform: rotateX(0); 
    opacity: 1;
  }
}

.century-block {
  animation: timeline-entry 0.8s ease-out forwards;
}
</style>